import { computed, ref, watch } from '@vue/composition-api'
import { isArray, isEmpty, sortBy as lodashSortBy } from 'lodash-es'
import moment from 'moment'
import Vue from 'vue'
import { useClipboard } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'

import {
  iataCodeVJ2, iataCodeVJ3, resolveSsrNameByCode, resolveTitle,
} from '@/constants/selectOptions'
import {
  apiReservation, apiAgencies, apiCustomer, apiRetail,
} from '@/api'
import { getUserData } from '@/api/auth/utils'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import { convertISODateTime } from '@core/utils/filter'

import useToast from '@useToast'

export default function useReservationHandle() {
  // Use toast
  const { toastError, toastSuccess } = useToast()
  const { route } = useRouter()

  const perfectScrollbarSettings = {
    maxScrollbarLength: 60,
    wheelPropagation: true,
  }

  // Refs
  const refReservationListTable = ref(null)
  const reservationsData = ref(null)

  // Enum filter
  const optionsAirLine = computed(() => store.getters['globalConfig/getConfig']('airlines'))

  const optionsSource = computed(() => {
    const sources = store.getters['globalConfig/getAllDistributors'].map(
      item => ({
        key: item,
        value: store.getters['globalConfig/getDistributorNameByCode'](item),
      }),
    )
    return sources
  })

  const optionsStatus = computed(() => store.getters['globalConfig/getConfig']('reservationStatus'))
  const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

  // Table Handlers
  const tableColumns = [
    { label: 'index', key: 'index' },
    { label: 'source', key: 'source' }, // thStyle: 'max-width: 10px'
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'status', key: 'status' },
    { label: '', key: 'rebook' }, // thStyle: 'max-width: 10px'
    { label: 'passenger', key: 'passenger' },
    { label: 'flight', key: 'flight' },
    { label: 'price', key: 'price' }, // Anh tuấn bảo bỏ đi vì giá ko đúng
    { label: 'priceNet', key: 'priceNet' },
    { label: 'createdAt', key: 'createdAt', sortable: true },
    { label: 'booker', key: 'booker' },
    { label: 'action', key: 'action' },
    { label: 'note', key: 'note' },
  ]

  if (isRoleF1.value) tableColumns.push({ label: 'ip', key: 'ip' })

  const sizePerPage = ref(40)
  const totalReservations = ref(0)
  const currentPage = ref(1)

  // Loading
  const loading = ref(false)
  const loadingRefetchBooking = ref(false)

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  // Filter
  const isActiveFilter = ref(true)
  const searchTextFilter = ref(null)
  const bookingCodeFilter = ref(null)
  const createdByFilter = ref(null)
  const agencyCodeFilter = ref(null)
  const startDateFilter = ref(null)
  const endDateFilter = ref(null)
  const startDateFlightFilter = ref(null)
  const endDateFlightFilter = ref(null)
  const statusFilter = ref(null)
  const statusFilterByUser = ref(null)
  const brandFilter = ref(null)
  const sourceFilter = ref(null)

  // Empty filter
  const isEmptyFilter = computed(
    () => !!(
      statusFilterByUser.value === null
        && startDateFilter.value === null
        && endDateFilter.value === null
        && startDateFlightFilter.value === null
        && endDateFlightFilter.value === null
        && createdByFilter.value === null
        && agencyCodeFilter.value === null
        && brandFilter.value === null
        && sourceFilter.value === null
    ),
  )

  const dataMeta = computed(() => {
    const localItemsCount = refReservationListTable.value
      ? refReservationListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReservations.value,
    }
  })

  const refetchData = () => {
    if (refReservationListTable.value) {
      refReservationListTable.value.refresh()
    }
  }

  watch([route], () => {
    const paths = route.value.path.split('/')
    if (paths.includes('bookings') && paths[paths.length - 1] !== 'bookings') {
      statusFilterByUser.value = paths[paths.length - 1].toUpperCase()
    } else {
      statusFilterByUser.value = null
    }
    refetchData()
  }, { immediate: true })

  watch([statusFilterByUser], () => {
    statusFilter.value = statusFilterByUser.value
  }, { immediate: true })

  watch([searchTextFilter, bookingCodeFilter], () => {
    statusFilter.value = null
  })

  const clearFilter = () => {
    searchTextFilter.value = null
    bookingCodeFilter.value = null
    startDateFilter.value = null
    createdByFilter.value = null
    agencyCodeFilter.value = null
    endDateFilter.value = null
    isActiveFilter.value = true
    startDateFlightFilter.value = null
    endDateFlightFilter.value = null
    statusFilterByUser.value = null
    brandFilter.value = null
    sourceFilter.value = null
    refetchData()
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, searchTextFilter, bookingCodeFilter, isActiveFilter],
    () => {
      refetchData()
    },
  )

  const resetStore = () => {
    store.dispatch('app-reservation/resetStore')
  }
  const resetSearchStore = () => {
    store.dispatch('app-reservation/resetSearchStore')
  }

  const resetSeatMapData = () => {
    // USING: CHANGE FLIGHT, ADD FLIGHT, CANCEL SEAT, MODIFY SEAT
    store.dispatch('app-reservation/removeSeatMapData')
  }

  const getAirportsByCodeList = airportCodeList => store.dispatch('app-reservation/fetchAirportsByCodes', airportCodeList)
  const getAirportByAirportCode = airportCode => store.getters['app-reservation/getAirportByAirportCode'](airportCode)

  const bookingSource = computed(() => store.getters['app-reservation/getBookingSource'])

  function warningDelayHandleQH(type) {
    const htmlText = '<span class="fw-700">Do kết nối hãng thường không ổn định, đại lý vui lòng chờ trong vài phút (nếu có tình trạng loading lâu).</span> <p class="fw-700">Sau đó 5 phút vui lòng mở lại booking để kiểm tra đã có số vé/hành lý/chỗ ngồi hay chưa trước khi thao tác lại, hoặc gửi booker để kiểm tra!</p> <p><i class="text-airline">Đang thực hiện, vui lòng đợi ...</i></p>'

    if (!type) {
      Vue.swal({
        title: '<span class="fw-700 text-warning">LƯU Ý !</span>',
        icon: 'warning',
        html: htmlText,
        focusConfirm: true,
        confirmButtonText: 'Đã hiểu!',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-warning rounded-lg py-75',
        },
        allowOutsideClick: () => !loading.value,
        didOpen: () => {
          if (loading.value) {
            Vue.swal.showLoading()
          }
        },
      })
    } else if (type === 'close') {
      Vue.swal.close()
    }
  }

  const refetchBookingData = (showLoading = true) => {
    loadingRefetchBooking.value = true && showLoading

    return store
      .dispatch('app-reservation/reFetchBookingById', { ignoreLoading: !showLoading })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'Tải lại danh sách thất bại',
        })
      })
      .finally(() => {
        loadingRefetchBooking.value = false
      })
  }

  const calculatePriceBookingLoading = ref(false)
  function calculatePriceBooking(payload) {
    calculatePriceBookingLoading.value = true
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-reservation/calculatePriceBooking', payload)
        .then(res => {
          resolve(res)
          refetchBookingData()
        })
        .catch(e => reject(e))
        .finally(() => {
          calculatePriceBookingLoading.value = false
          loading.value = false
        })
    })
  }

  function cleanAirPricingConversion(payload) {
    store
      .dispatch('app-reservation/cleanAirPricingConversion', payload)
  }

  // ANCHOR fetchBookingById
  const fetchBookingById = (id = null, setId = true) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/fetchBookingById', { id, setId })
      .then(res => resolve(res))
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Tải booking thất bại',
        })
      })
      .finally(() => {
        if (!calculatePriceBookingLoading.value) {
          loading.value = false
        }
      })
  })

  // ANCHOR fetchPastDayBookingById
  const fetchPastDayBookingById = (id = null) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/fetchPastDayBookingById', { id })
      .then(res => resolve(res))
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Tải booking thất bại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const retrieveBooking = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/retrieveBooking', payload) // { source, pnrNumber }
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loading.value = false
      })
  })

  // function recalculatePriceCommand(payload) {
  //   return new Promise((resolve, reject) => {
  //     store
  //       .dispatch('app-reservation/recalculatePriceCommand', payload)
  //       .then(res => {
  //         resolve(res)
  //         if (payload.retain) refetchBookingData()
  //       })
  //       .catch(e => reject(e))
  //       .finally(() => {
  //         loading.value = false
  //       })
  //   })
  // }
  function recalculatePriceCommandAddDob(payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-reservation/recalculatePriceCommandAddDob', payload)
        .then(res => {
          refetchBookingData()
          resolve(res)
        })
        .catch(e => reject(e))
        .finally(() => {
          loading.value = false
        })
    })
  }

  function recalculatePriceItinerary(payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-reservation/recalculatePriceItinerary', payload)
        .then(res => {
          resolve(res)
          if (payload.retain) refetchBookingData()
        })
        .catch(e => reject(e))
        .finally(() => {
          loading.value = false
        })
    })
  }

  const flightPayment = payload => new Promise((resolve, reject) => {
    loading.value = true
    if (bookingSource.value.includes('QH')) warningDelayHandleQH()

    store.dispatch('app-reservation/flightPayment', payload)
      .then(res => {
        refetchBookingData()
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'Thanh toán thành công',
        })
        if (bookingSource.value.includes('QH')) warningDelayHandleQH('close')
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi Thanh toán',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // ANCHOR Ticketing for VN
  const ticketing = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/ticketing', payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'Thanh toán thành công',
        })
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi Thanh toán',
        })
      })
      .finally(() => {
        refetchBookingData()
        loading.value = false
      })
  })

  // ANCHOR Modify Cancel-flight
  const cancelFlightPrice = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-reservation/cancelFlightPrice', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi kiểm tra giá huỷ chuyến bay.',
        })
      })
  })

  const cancelFlight = payload => new Promise((resolve, reject) => {
    loading.value = true
    if (bookingSource.value.includes('QH')) warningDelayHandleQH()

    store
      .dispatch('app-reservation/cancelFlight', payload)
      .then(res => {
        resolve(res)
        refetchBookingData()
        if (bookingSource.value.includes('QH')) warningDelayHandleQH('close')
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const cancelFlight1G = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/cancelFlight1G', payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'reservation.cancelFlightSuccessful',
        })
        refetchBookingData()
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'reservation.cancelFlightError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // for cancel VN1A
  const commandsGetBooking = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/commandsGetBooking', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi tải hành trình',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const commandsCancelSegment = (payload, reload = false) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/commandsCancelSegment', payload)
      .then(res => {
        resolve(res)
        if (reload) refetchBookingData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'reservation.cancelFlightSuccessful',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'reservation.cancelFlightError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const separateBooking = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/separateBooking', payload)
      .then(res => {
        resolve(res)
        refetchBookingData()
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        loading.value = false
      })
  })

  // ANCHOR: Send Email // language, customEmail
  const sendEmail = (bookingData, dataSendEmail) => new Promise((resolve, reject) => {
    loading.value = true
    const bookingSource = bookingData?.source || ''

    // Gửi email trong airlineContact lên
    const emails = []
    const email = bookingData?.contact?.paxInfo?.email[0]?.address
    if (email) {
      emails.push(email)
    }

    const payload = {
      language: dataSendEmail.language,
      source: bookingSource,
      airline: bookingData.airlines.split(' | ')[0],
      pnrNumber: bookingData.bookingCode,
      isHiddenFare: ['VJ'].includes(bookingSource) ? dataSendEmail.isHiddenFare : undefined,
    }

    if (['VN1A_MT', 'VN1A'].includes(bookingSource)) {
      payload.emailAddress = dataSendEmail.email || emails[0]
      payload.isGetETicket = dataSendEmail.isGetETicket
    } else {
      payload.emails = emails
    }

    const apiSend = ['VN1A_MT', 'VN1A'].includes(bookingSource) ? 'sendEmailCommand' : 'sendEmail'

    store
      .dispatch(`app-reservation/${apiSend}`, payload)
      .then(() => {
        resolve()
        toastSuccess({
          title: 'messagesList.success',
          content: 'reservation.sendEmail.sendEmailSuccess',
        })
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'reservation.sendEmail.sendEmailError',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // eslint-disable-next-line no-unused-vars
  const retailReportCustomer = payload => new Promise((resolve, reject) => {
    loading.value = true
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value || '',
      bookingCode: bookingCodeFilter.value || '',
      createdById: createdByFilter.value?.id || undefined,
      agencyCode: agencyCodeFilter.value || undefined,
      startDate: startDateFilter.value ? `${startDateFilter.value}` : null,
      endDate: endDateFilter.value ? `${endDateFilter.value}` : null,
      startFlightDate: startDateFlightFilter.value ? `${startDateFlightFilter.value}` : null,
      endFlightDate: endDateFlightFilter.value ? `${endDateFlightFilter.value}` : null,
      airline: brandFilter.value,
      source: sourceFilter.value,
      status: statusFilter.value && statusFilter.value === 'REPORT-BOOKINGS' ? '' : statusFilter.value,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      isActive: isActiveFilter.value !== null && !bookingCodeFilter.value ? isActiveFilter.value : undefined,
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }

    if (payload.status === '' || !payload.status) {
      delete payload.status
    }

    apiRetail.getAllBookingRetail(payload)
      .then(response => {
        if (response.status === 200) {
          totalReservations.value = response.data.total
          resolve(response.data.items)
        }
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'Tải danh sách booking thất bại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const fetchBookings = (ctx, callback) => {
    loading.value = true
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value || '',
      bookingCode: bookingCodeFilter.value || '',
      createdById: createdByFilter.value?.id || null,
      agencyCode: agencyCodeFilter.value?.agencyCode || null,
      startDate: startDateFilter.value ? `${startDateFilter.value}` : null,
      endDate: endDateFilter.value ? `${endDateFilter.value}` : null,
      startFlightDate: startDateFlightFilter.value ? `${startDateFlightFilter.value}` : null,
      endFlightDate: endDateFlightFilter.value ? `${endDateFlightFilter.value}` : null,
      airline: brandFilter.value,
      source: sourceFilter.value,
      status: statusFilter.value || null,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      isActive: isActiveFilter.value !== null && isActiveFilter.value !== 'lockBalance' && !bookingCodeFilter.value ? isActiveFilter.value : undefined,
      isEnableAutoIssue: isActiveFilter.value === 'lockBalance' ? true : undefined,
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }
    store
      .dispatch('app-reservation/fetchBookings', payload)
      .then(response => {
        totalReservations.value = response.data.total
        reservationsData.value = response.data
        callback(reservationsData.value.items)
        return reservationsData.value.items
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'Tải danh sách booking thất bại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchPastDayBookings = (ctx, callback) => {
    loading.value = true
    const payload = {
      size: sizePerPage.value,
      page: currentPage.value,
      searchText: searchTextFilter.value || '',
      bookingCode: bookingCodeFilter.value || '',
      createdById: createdByFilter.value?.id || null,
      agencyCode: agencyCodeFilter.value?.agencyCode || null,
      startDate: startDateFilter.value ? `${startDateFilter.value}` : null,
      endDate: endDateFilter.value ? `${endDateFilter.value}` : null,
      startFlightDate: startDateFlightFilter.value ? `${startDateFlightFilter.value}` : null,
      endFlightDate: endDateFlightFilter.value ? `${endDateFlightFilter.value}` : null,
      airline: brandFilter.value,
      source: sourceFilter.value,
      status: statusFilter.value || null,
      sortBy: sortBy.value ? sortBy.value : 'dateCreate',
      isActive: isActiveFilter.value !== null && !bookingCodeFilter.value ? isActiveFilter.value : undefined,
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
    }
    store
      .dispatch('app-reservation/fetchPastDayBookings', payload)
      .then(response => {
        totalReservations.value = response.data.total
        reservationsData.value = response.data
        callback(reservationsData.value.items)
        return reservationsData.value.items
      })
      .catch(error => {
        console.error({ error })
        toastError({
          title: 'messagesList.error',
          content: 'Tải danh sách booking thất bại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateNote = (
    reservationId,
    dataToUpdate,
    isModify = false,
    isAuto = false,
  ) => new Promise((resolve, reject) => {
    store
      .dispatch('app-reservation/updateNote', {
        id: reservationId,
        dataToUpdate,
      })
      .then(() => {
        resolve()

        if (isModify) {
          refetchBookingData()
        }
        if (!isAuto) {
          toastSuccess({
            title: 'messagesList.success',
            content: 'Cập nhật ghi chú thành công',
          })
        }
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'Cập nhật ghi chú thất bại',
        })
      })
  })

  const updateAutoIssueTime = (reservationId, dataToUpdate) => new Promise((resolve, reject) => {
    store
      .dispatch('app-reservation/updateAutoIssueTime', {
        id: reservationId,
        dataToUpdate,
      })
      .then(() => {
        resolve()

        toastSuccess({
          title: 'messagesList.success',
          content: 'Đặt giờ xuất vé thành công',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'Đặt giờ xuất vé thất bại',
        })
      })
  })

  const exportBooking = payload => new Promise((resolve, reject) => {
    store
      .dispatch('app-reservation/exportBooking', payload)
      .then(() => {
        resolve()

        toastSuccess({
          title: 'messagesList.success',
          content: 'Xuất Excel thành công',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'Xuất vé không thành công, vui lòng kiểm tra lại',
        })
      })
  })

  const updateResetIssueTime = reservationId => apiReservation.updateUnsetIssueTime(reservationId).then(() => {
    toastSuccess({
      title: 'messagesList.success',
      content: 'Huỷ giờ xuất vé thành công',
    })
  })

  const activeReservation = (reservationId, from = 'list') => {
    store
      .dispatch('app-reservation/activeReservation', reservationId)
      .then(() => {
        if (from === 'list') refetchData()
        else if (from === 'detail') refetchBookingData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'reservation.message.activeReservationSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'reservation.message.activeReservationError',
        })
      })
  }

  const banBooking = (id, payload) => store
    .dispatch('app-reservation/banBooking', { id, payload })
    .then(() => {
      toastSuccess({
        title: 'messagesList.success',
        content: 'reservation.message.banBookingSuccess',
      })
    })
    .catch(() => {
      toastError({
        title: 'messagesList.error',
        content: 'reservation.message.banBookingError',
      })
    })

  const unbanBooking = id => store
    .dispatch('app-reservation/unbanBooking', id)
    .then(() => {
      toastSuccess({
        title: 'messagesList.success',
        content: 'reservation.message.unbanBookingSuccess',
      })
    })
    .catch(() => {
      toastError({
        title: 'messagesList.error',
        content: 'reservation.message.unbanBookingError',
      })
    })

  const submitBooking = (id, payload) => store
    .dispatch('app-reservation/submitBooking', { id, payload })
    .then(res => {
      if (res.failedItems.length === 0) {
        toastSuccess({
          title: 'messagesList.success',
          content: 'reservation.message.submitBookingSuccess',
        })
      } else {
        toastError({
          title: 'messagesList.error',
          content: `${res.failedItems[0]?.bookingCode} ${res.failedItems[0]?.message}`,
        })
      }
    })
    .catch(() => {
      toastError({
        title: 'messagesList.error',
        content: 'reservation.message.submitBookingError',
      })
    })

  const sendSubmitBookingMail = id => store
    .dispatch('app-reservation/sendSubmitBookingMail', id)
    .then(() => {
      toastSuccess({
        title: 'messagesList.success',
        content: 'reservation.message.sendSubmitBookingMailSuccess',
      })
    })
    .catch(() => {
      toastError({
        title: 'messagesList.error',
        content: 'reservation.message.sendSubmitBookingMailError',
      })
    })

  const deActiveReservation = (reservationId, from = 'list') => {
    store
      .dispatch('app-reservation/deActiveReservation', reservationId)
      .then(() => {
        if (from === 'list') refetchData()
        else if (from === 'detail') refetchBookingData()
        toastSuccess({
          title: 'messagesList.success',
          content: 'reservation.message.deactiveReservationSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'reservation.message.deactiveReservationError',
        })
      })
  }

  const reBookReservation = (id, rebookData) => new Promise((resolve, reject) => {
    const payload = {
      id,
      params: {
        rebookType: rebookData.rebookType,
      },
    }

    store
      .dispatch('app-reservation/reBookReservation', payload)
      .then(res => {
        resolve(res)

        toastSuccess({
          title: 'messagesList.success',
          content: 'Đặt lại vé thành công',
        })
      })
      .catch(e => {
        reject(e)

        toastError({
          title: 'messagesList.error',
          content: 'Có lỗi xảy ra, vui lòng kiểm tra lại thông tin!',
        })
      })
  })

  const modifyPaxInfo = (bookingData, updateData, isShowToast = true, isCommand = false, isReload = true) => new Promise((resolve, reject) => {
    const airlineData = {
      airline: bookingData.airlines.split(' | ')[0],
      agencyCode: bookingData.agency,
      paxCode: bookingData.paxContact?.code ?? bookingData.paxContact,
      pnrNumber: bookingData.bookingCode,
      source: bookingData.source,
    }
    const payload = {
      ...airlineData,
      ...updateData,
      contact: getUserData().employeeData.id,
    }

    const funcName = isCommand ? 'commandModifyPaxInfo' : 'modifyPaxInfo'

    store
      .dispatch(`app-reservation/${funcName}`, payload)
      .then(res => {
        // VN1A: đổi tên khách không reload booking ở đây
        if (isReload) refetchBookingData(!airlineData.source.includes('VN1A')) // ko hiển thị loading vì VNA dùng command
        resolve(res)
        if (isShowToast) {
          toastSuccess({
            title: 'messagesList.success',
            content: 'Cập nhật thông tin thành công',
          })
        }
      })
      .catch(e => {
        reject(e)
        if (isShowToast) {
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi xảy ra, vui lòng kiểm tra lại!',
          })
        }
      })
  })

  const paxInfoPrice = (bookingData, updateData) => new Promise((resolve, reject) => {
    const airlineData = {
      airline: bookingData.airlines.split(' | ')[0],
      agencyCode: bookingData.agency,
      paxCode: bookingData.paxContact?.code ?? bookingData.paxContact,
      pnrNumber: bookingData.bookingCode,
    }

    const payload = {
      ...airlineData,
      ...updateData,
      contact: getUserData().employeeData.id,
      source: bookingData.source,
    }

    store
      .dispatch('app-reservation/paxInfoPrice', payload)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Có lỗi xảy ra, vui lòng kiểm tra lại!',
        })
      })
  })

  const createPaxList = pLists => {
    const paxLists = []
    const adult = pLists.filter(p => p.paxType === 'ADULT')
    if (!isEmpty(adult)) {
      paxLists.push({
        type: 'ADULT',
        count: adult.length,
      })
    }
    const child = pLists.filter(p => p.paxType === 'CHILD')
    if (!isEmpty(child)) {
      paxLists.push({
        type: 'CHILD',
        count: child.length,
      })
    }

    return paxLists
  }

  const GROUP_CLASS_BY_CLASS_BOOKING = {
    G: 'ECONOMY',
    A: 'ECONOMY',
    R: 'ECONOMY',
    Y: 'ECONOMY',
    N: 'ECONOMY',
    I: 'BUSINESS',
    JC: 'BUSINESS',
  }

  function getGroupClass(segment) {
    if (segment.groupClass === '') return ''

    if (segment.airline === 'QH') {
      // FIXME - fix cho những case QH không có groupClass, cần update thêm bookingClass
      if (!segment.groupClass && segment.bookingClass) return GROUP_CLASS_BY_CLASS_BOOKING[segment.bookingClass] || ''

      if (segment.groupClass.includes('ECONOMY')) return 'ECONOMY'
      if (segment.groupClass.includes('BUSINESS')) return 'BUSINESS'
    }

    return segment.groupClass
  }

  const createItineraries = (data, source = null) => {
    const result = []
    const airline = data[0][0].airline

    if (['VJ', 'VZ'].includes(airline)) {
      data.map((trip, tripIndex) => result.push({
        airline: trip[0].airline,
        source: trip[0].airline === 'VZ' ? 'VJ' : trip[0].airline,
        departure: trip[0].departure.iataCode,
        arrival: trip[trip.length - 1].arrival.iataCode,
        departureDate: convertISODateTime(
          trip[0].departure.at,
          trip[0].departure.timeZone,
        ).ISOdatetime,
        arrivalDate: convertISODateTime(
          trip[trip.length - 1].arrival.at,
          trip[trip.length - 1].arrival.timeZone,
        ).ISOdatetime,
        flightNumber: trip[0].flightNumber,
        fareBasisCode: '',
        segmentId: trip[0].segmentId,
        fareValue: trip[0].segmentValue,
        bookingClass: trip[0].bookingClass,
        groupClass: trip[0].groupClass || '',
        itineraryId: `${tripIndex + 1}`,
      }))
    } else {
      data.map(trip => trip.map(segment => result.push({
        airline: segment.airline,
        source: source ?? segment.airline,
        departure: segment.departure.iataCode,
        arrival: segment.arrival.iataCode,
        departureDate: convertISODateTime(
          segment.departure.at,
          segment.departure.timeZone,
        ).ISOdatetime,
        arrivalDate: convertISODateTime(
          segment.arrival.at,
          segment.arrival.timeZone,
        ).ISOdatetime,
        flightNumber: segment.flightNumber,
        fareBasisCode: segment?.fareBasisCode || '',
        bookingClass: segment.bookingClass,
        groupClass: getGroupClass(segment),
        segmentId: segment.segmentId || null,
        ...(['VU'].includes(source) && {
          itineraryId: segment.itineraryId,
        }),
        ...(['VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(source) && {
          itineraryId: segment.devItineraryIndex,
        }),
        ...(['TH'].includes(source) && {
          fareValue: segment.segmentValue,
        }),
      })))
    }

    return result
  }

  const fetchListAncillary = ({
    bookingCode, source, itineraries, paxLists,
  }) => new Promise((resolve, reject) => {
    if (isEmpty(itineraries)) return

    const payload = {
      source,
      pnrNumber: bookingCode,
      paxList: createPaxList(paxLists),
      itineraries: createItineraries(itineraries, source),
    }

    store
      .dispatch('app-reservation/fetchListAncillary', payload)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi lấy dữ liệu gói mua thêm',
        })
        reject(err)
      })
  })

  const fetchSeatMap = ({
    bookingCode, source, itineraries, paxLists,
  }) => new Promise((resolve, reject) => {
    if (isEmpty(itineraries)) return

    const payload = {
      source,
      pnrNumber: bookingCode,
      paxList: createPaxList(paxLists),
      itineraries: createItineraries(itineraries, source),
    }

    store
      .dispatch('app-reservation/fetchSeatMap', payload)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi lấy dữ liệu đặt chỗ.',
        })
        reject(err)
      })
  })

  const modifyAncillary = (bookingData, ssrData, paymentData) => new Promise((resolve, reject) => {
    const payload = {
      ancillaryServiceUpdates: ssrData,
      airline: bookingData.airlines.split(' | ')[0],
      source: bookingData.source,
      ...(paymentData.isPayNow && {
        isPaymentImmediate: true,
        paymentPassword: paymentData.paymentPassword,
      }),
      agencyCode: bookingData.agency,
      paxCode: bookingData.paxContact?.code ?? bookingData.paxContact,
      pnrNumber: bookingData.bookingCode,
      contact: getUserData().employeeData.id,
      requestUuid: uuidv4(),
    }

    loading.value = true
    if (['QH', 'AK'].includes(bookingSource.value)) warningDelayHandleQH()

    store
      .dispatch('app-reservation/modifyAncillary', payload)
      .then(res => {
        refetchBookingData()

        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'Thay đổi thành công.',
        })
        if (['QH', 'AK'].includes(bookingSource.value)) warningDelayHandleQH('close')
      })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi thực hiện hành động, vui lòng kiểm tra lại',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  // VNA
  const ancillaryPayments = (bookingData, ssrData, paymentData) => new Promise((resolve, reject) => {
    const payload = {
      contact: getUserData().employeeData.id,
      source: bookingData.source,
      pnrNumber: bookingData.bookingCode,
      currency: 'VND',
      ancillaryInvoices: ssrData,
      ...(paymentData.isPayNow && {
        isPaymentImmediate: true,
        paymentPassword: paymentData.paymentPassword,
      }),
    }

    store
      .dispatch('app-reservation/ancillaryPayments', payload)
      .then(res => {
        refetchBookingData()
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'Thanh toán dịch vụ thành công',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi thanh toán dịch vụ, vui lòng kiểm tra lại',
        })
        reject(e)
      })
  })

  // ANCHOR Modify Seat
  const modifyReserveSeat = (bookingData, seatData, paymentData) => new Promise((resolve, reject) => {
    const payload = {
      contact: getUserData().employeeData.id,
      source: bookingData.source,
      agencyCode: bookingData.agency,
      ...(paymentData.isPayNow && {
        isPaymentImmediate: true,
        paymentPassword: paymentData.paymentPassword,
      }),
      paxCode: bookingData.paxContact.code,
      pnrNumber: bookingData.bookingCode,
      seatAssignments: seatData,
      requestUuid: uuidv4(),
    }

    loading.value = true
    if (['QH', 'AK'].includes(bookingSource.value)) warningDelayHandleQH()

    store
      .dispatch('app-reservation/modifyReserveSeat', payload)
      .then(res => {
        refetchBookingData()
        resetSeatMapData()

        toastSuccess({
          title: 'messagesList.success',
          content: 'Thêm chỗ ngồi thành công',
        })
        resolve(res)
        if (['QH', 'AK'].includes(bookingSource.value)) warningDelayHandleQH('close')
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'Có lỗi xảy ra khi thêm chỗ ngồi!',
        })
        reject(e)
      })
      .finally(() => {
        loading.value = true
      })
  })

  // VNA
  const modifyCancelSeat = (bookingData, seatData) => new Promise((resolve, reject) => {
    const payload = {
      contact: getUserData().employeeData.id,
      source: bookingData.source,
      agencyCode: bookingData.agency,
      paxCode: bookingData.paxContact.code,
      pnrNumber: bookingData.bookingCode,
      seatCancelation: seatData,
    }

    store
      .dispatch('app-reservation/modifyCancelSeat', payload)
      .then(res => {
        refetchBookingData()
        resetSeatMapData()

        toastSuccess({
          title: 'messagesList.success',
          content: 'Huỷ chỗ ngồi thành công',
        })
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'Có lỗi xảy ra khi huỷ chỗ ngồi, vui lòng kiểm tra lại!',
        })
        reject(e)
      })
  })

  // ANCHOR Modify Add-flights
  const addFlights = (payload, isResetStore = true) => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/addFlights', payload)
      .then(res => {
        resolve(res)

        if (isResetStore) resetSearchStore()

        // if (quickAdd) {
        //   refetchBookingData()
        // }
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const changeFlights = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/changeFlights', payload)
      .then(res => {
        resolve(res)
        resetSearchStore()
        refetchBookingData()
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const addFlightsPrice = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/addFlightsPrice', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const changeFlightsPrice = payload => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-reservation/changeFlightsPrice', payload)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        loading.value = false
      })
  })

  const filterAdultPassengers = passengers => {
    const adultPassengers = passengers.filter(
      passenger => passenger.paxType === 'ADULT',
    )
    const infantParentIds = passengers
      .filter(passenger => passenger.paxType === 'INFANT')
      .map(infant => infant.parentPaxId)

    const filteredPassengers = adultPassengers.filter(
      passenger => !infantParentIds.includes(passenger.paxId),
    )

    return filteredPassengers
  }

  // ANCHOR GETTER FROM STORE
  const getBookingData = computed(
    () => store.getters['app-reservation/getBookingData'],
  )

  const NDC_1A_BOOKING = computed(() => {
    if (getBookingData.value && !isEmpty(getBookingData.value?.supplierPNRs)) {
      return getBookingData.value?.supplierPNRs.some(s => s.supplierCode && s.supplierCode.includes('NDC'))
    }
    return false
  })

  const getSeatMapData = computed(
    () => store.getters['app-reservation/getSeatMapData'],
  )
  const getIsEmptySeatMapData = computed(
    () => store.getters['app-reservation/getIsEmptySeatMapData'],
  )

  const getAncillaryData = computed(
    () => store.getters['app-reservation/getAncillaryData'],
  )
  const getOldItineraries = computed(
    () => store.getters['app-reservation/getOldItineraries'],
  )

  const currentBreakpoint = computed(
    () => store.getters['app/currentBreakPoint'],
  )

  const resolveContactTypeVariant = type => {
    if (type === 'Adult') return 'primary'
    if (type === 'Child') return 'light-primary'
    if (type === 'Infant') return 'light-danger'
    return 'primary'
  }

  function modifyDocuments(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/modifyDocuments', payload)
        .then(res => {
          refetchBookingData()
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.modifyDocumentsSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.modifyDocumentsError',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function modifyFrequentFlyer(bookingData, pax, ope, dataModify) {
    const payload = {
      frequentFlyerTSAUpdate: {
        paxInfo: {
          firstName: pax.firstName,
          lastName: pax.lastName,
          title: pax.title ? pax.title : resolveTitle(pax.firstName.split(' ')[pax.firstName.split(' ').length - 1]),
          paxType: pax.paxType,
          birthday: pax.birthday || null,
          paxId: pax.paxId,
          parentPaxId: pax.parentPaxId,
          familyId: pax?.familyId || pax?.parentPaxId,
          pricingInfo: pax.pricingInfo,
          travelFlyer: '',
          document: pax?.document || [],
          fareBasisCode: pax?.fareBasisCode || '',
        },
        frequentFlyer: dataModify,
        operation: ope.operation,
        id: ope.id,
      },
      source: bookingData.source,
      airline: bookingData.airlines.split(' | ')[0],
      agencyCode: bookingData.agency,
      paxCode: bookingData.paxContact?.code ?? bookingData.paxContact,
      pnrNumber: bookingData.bookingCode,
      bookingId: bookingData?.bookingInfoId || null,
    }

    return new Promise((resolve, reject) => {
      loading.value = true

      store
        .dispatch('app-reservation/modifyFrequentFlyer', payload)
        .then(res => {
          refetchBookingData()
          resolve(res)
          if (ope.operation === 'ADD') {
            toastSuccess({
              title: 'messagesList.success',
              content: 'messagesList.reservation.modifyFrequentFlyerSuccess',
            })
          }
        })
        .catch(error => {
          reject(error)
          if (ope.operation === 'ADD') {
            toastError({
              title: 'messagesList.error',
              content: 'messagesList.reservation.modifyFrequentFlyerError',
            })
          }
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function fetchEticketByNumber(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      const apiPath = 'fetchEticketByNumber'
      const req = payload

      store
        .dispatch(`app-reservation/${apiPath}`, req)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  const voidTicket = payload => new Promise((resolve, reject) => {
    const { source } = payload
    const callVoidV2 = source === '1G'
    loading.value = true
    // 1G gọi void v2 (https://discord.com/channels/1054696448110903327/1055496658684743721/1217171864355475486)

    if (bookingSource.value.includes('QH')) warningDelayHandleQH()

    store
      .dispatch(`app-reservation/voidTicket${callVoidV2 ? 'V2' : ''}`, payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.reservation.voidTicketSuccess',
        })
        if (bookingSource.value.includes('QH')) warningDelayHandleQH('close')
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.reservation.voidTicketError',
        })
      })
      .finally(() => {
        refetchBookingData()
        loading.value = false
      })
  })

  const eTicketUpdates = (bookingData, ticketData) => new Promise((resolve, reject) => {
    // loading.value = true

    const payload = {
      contact: getUserData().employeeData.id,
      source: bookingData.source,
      agencyCode: bookingData.agency,
      paxCode: bookingData.paxContact?.code ?? bookingData.paxContact,
      pnrNumber: bookingData.bookingCode,
      ticketUpdates: ticketData,
    }

    store
      .dispatch('app-reservation/eTicketUpdates', payload)
      .then(res => {
        resolve(res)
        // toastSuccess({
        //   title: 'messagesList.success',
        //   content: 'Cập nhật thành công',
        // })
      })
      .catch(error => {
        reject(error)
        // toastError({
        //   title: 'messagesList.error',
        //   content: 'Có lỗi xảy ra, vui lòng kiểm tra lại!',
        // })
      })
      .finally(() => {
        refetchBookingData()
        // loading.value = false
      })
  })

  const refundTicketAmount = payload => new Promise((resolve, reject) => store
    .dispatch('app-reservation/refundTicketAmount', payload)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      reject(error)
      // toastError({ title: 'messagesList.error', content: 'messagesList.reservation.refundTicketAmountError' })
    }))

  const refundTickets = payload => new Promise((resolve, reject) => store
    .dispatch('app-reservation/refundTickets', payload)
    .then(res => {
      refetchBookingData()
      toastSuccess({
        title: 'messagesList.success',
        content: 'messagesList.reservation.refundTicketsSuccess',
      })
      resolve(res)
    })
    .catch(error => {
      reject(error)
      toastError({
        title: 'messagesList.error',
        content: 'messagesList.reservation.refundTicketError',
      })
    }))

  const exchangeTicketAmount = payload => new Promise((resolve, reject) => store
    .dispatch('app-reservation/exchangeTicketAmount', payload)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      reject(error)
      // toastError({ title: 'messagesList.error', content: 'messagesList.reservation.refundTicketAmountError' })
    }))

  const exchangeTickets = payload => new Promise((resolve, reject) => store
    .dispatch('app-reservation/exchangeTickets', payload)
    .then(res => {
      refetchBookingData()
      toastSuccess({
        title: 'messagesList.success',
        content: 'messagesList.reservation.exchangeTicketsSuccess',
      })
      resolve(res)
    })
    .catch(error => {
      reject(error)
      toastError({
        title: 'messagesList.error',
        content: 'messagesList.reservation.exchangeTicketError',
      })
    }))

  function getCommissions(params) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/getCommissions', params)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.getCommissionsError',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function checkTicketing(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/checkTicketing', id)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.getCommissionsError',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function shortenBooking(payload) {
    return new Promise((resolve, reject) => {
      // loading.value = true
      store
        .dispatch('app-reservation/shortenBooking', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.shortenBookingError',
          })
        })
        .finally(() => {
          // loading.value = false
        })
    })
  }

  function shortenEticket(payload) {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-reservation/shortenEticket', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.shortenEticketError',
          })
        })
    })
  }

  // function retrieveTicket(payload) {
  //   return new Promise((resolve, reject) => {
  //     store
  //       .dispatch('app-reservation/retrieveTicket', payload)
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(error => {
  //         reject(error)
  //         toastError({
  //           title: 'messagesList.error',
  //           content: 'messagesList.reservation.retrieveEticketError',
  //         })
  //       })
  //   })
  // }

  function confirmSegments(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/confirmSegments', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.confirmSegmentsSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.confirmSegmentsError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function confirmFlightsSegments(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/confirmFlightsSegments', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.confirmSegmentsSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.confirmSegmentsError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function modifyRemarks(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/modifyRemarks', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.modifyRemarksSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.modifyRemarksError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData(false)
        })
    })
  }

  function osiFlight(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/osiFlight', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.osiFlightSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.osiFlightError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function sendQueue(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/sendQueue', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.sendQueuesSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.sendQueuesError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  // add documents for vn1a
  function addDocumentsVN1A(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/addDocumentsVN1A', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.modifyDocumentsSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.modifyDocumentsError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  // ANCHOR bookSpecialServices
  function bookSpecialServices(payload, type = 'bookSSR') {
    let contentSuccess = 'messagesList.reservation.bookSpecialServicesSuccess'
    let contentError = 'messagesList.reservation.bookSpecialServicesError'
    if (type === 'editSSR') {
      contentSuccess = 'reservation.editCtcmCtceFor1g.contentSuccess'
      contentError = 'reservation.editCtcmCtceFor1g.contentError'
    }
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/bookSpecialServices', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: contentSuccess,
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: contentError,
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  // ANCHOR - Lounge, ...
  function getListSpecialServices(payload) {
    return new Promise((resolve, reject) => {
      // loading.value = true
      store
        .dispatch('app-reservation/getListSpecialServices', payload)
        .then(res => {
          resolve(res)
          // toastSuccess({
          //   title: 'messagesList.success',
          //   content: 'messagesList.reservation.bookSpecialServicesSuccess',
          // })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Không tìm thấy danh sách dịch vụ!',
          })
        })
        .finally(() => {
          // loading.value = false
          // refetchBookingData()
        })
    })
  }
  function createSpecialServices(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/createSpecialServices', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.reservation.bookSpecialServicesSuccess',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.reservation.bookSpecialServicesError',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function getInsurancePlans(payload) { // insurance
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-reservation/getInsurancePlans', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Không tìm thấy danh sách dịch vụ!',
          })
        })
    })
  }

  function bulkCreateInsuranceDraft(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/bulkCreateInsuranceDraft', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'Thêm gói bảo hiểm thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi khi thêm gói bảo hiểm',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }
  function updateInsuranceDraft(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/updateInsuranceDraft', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'Thay đổi gói bảo hiểm thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi đổi gói bảo hiểm',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }
  function deleteInsuranceDraft(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/deleteInsuranceDraft', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function createInsuranceCoverNote(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/createInsuranceCoverNote', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'Tạo cover note thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi khi tạo cover note',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function editInsuranceCoverNote(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/editInsuranceCoverNote', payload)
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'Sửa đổi cover note thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi khi sửa cover note',
          })
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function createInsurancePolicy(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/createInsurancePolicy', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  function cancelInsurance(payload) { // insurance
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/cancelInsurance', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          loading.value = false
          refetchBookingData()
        })
    })
  }

  const fetchAirportGroup = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-reservation/fetchAirportGroup')
      .then(response => resolve(response))
      .catch(error => reject(error))
  })

  const STATUS_NAME_ANCILLARY = {
    HD: 'Chưa thanh toán',
    HI: 'Đã thanh toán',
    HK: 'Đã xác nhận',
  }

  const resolveClassVJ = code => {
    const bClass = code.toUpperCase()

    if (bClass.includes('ECO')) return 'ECO'
    if (bClass.includes('DELUXE')) return 'DELUXE'
    if (bClass.includes('SKYBOSS BUSINESS')) return 'SKYBOSSBUSINESS'

    return code
  }

  const BG_WEIGHT_SSR_PAID = {
    VN: [23, 10],
    QH: [30, 25, 20, 15, 10, 5],
    VU: [40, 35, 30, 25, 20, 15],
    VJ: [100, 90, 80, 70, 60, 50, 40, 30, 20],
  }

  function getBaggageWeightBySsrPaid(airline, baggage) {
    if (!baggage) return 0
    const getAirline = ['VZ'].includes(airline) ? 'VJ' : airline
    const weights = BG_WEIGHT_SSR_PAID[getAirline] || []

    if (!isEmpty(weights)) {
      for (const weight of weights) {
        if (baggage.includes(weight.toString())) {
          return weight
        }
      }
    }

    return 0
  }

  function getFareRulesAirlineByBookingClass(airline, bookingClass) {
    const getAirline = airline === 'VZ' ? 'VJ' : airline
    const getBookingClass = getAirline.includes('VJ') ? resolveClassVJ(bookingClass) : bookingClass
    return store.getters['globalConfig/getFareRulesAirlineByBookingClass'](getAirline, getBookingClass)
  }

  function addArunksToItineraries(bookingData, flatSegment = false) {
    let itinerariesWithArunks
    if (flatSegment) {
      itinerariesWithArunks = [
        ...bookingData.itineraries.flat(),
        ...bookingData.arunks,
      ]
    } else {
      itinerariesWithArunks = [
        ...bookingData.itineraries,
        ...bookingData.arunks,
      ]
    }

    const result = lodashSortBy(itinerariesWithArunks, [
      item => {
        if (isArray(item)) {
          return item[0].segmentId
        }
        return item.segmentId
      },
    ])
    // const indexArray = []
    // let count = 1
    // result.forEach(item => {
    //   if (isArray(item)) {
    //     indexArray.push(count)
    //     count += 1
    //   } else {
    //     indexArray.push(0)
    //   }
    // })
    // indexFlightWithArunks.value = indexArray
    return result
  }

  const loadingAgencies = ref(false)
  // ANCHOR: for find by PNR
  const fetchAgencies = (searchText = undefined) => new Promise((resolve, reject) => {
    loading.value = true
    loadingAgencies.value = true

    apiAgencies
      .fetchAgencies({ searchText })
      .then(response => { resolve(response.data.items) })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loading.value = false
        loadingAgencies.value = false
      })
  })

  const loadingCustomers = ref(false)

  const fetchCustomers = (searchText = undefined) => new Promise((resolve, reject) => {
    loading.value = true
    loadingCustomers.value = true
    apiCustomer
      .fetchCustomers({ searchText })
      .then(response => { resolve(response.data.items) })
      .catch(e => {
        reject(e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
      })
      .finally(() => {
        loading.value = false
        loadingCustomers.value = false
      })
  })

  function getAirlineByOperating(flight) {
    // NOTE: Điều kiện vé cho QH_VCS
    if (flight.airline === 'QH' && [flight.departure?.IATACode, flight.departure?.iataCode, flight.arrival?.IATACode, flight.arrival?.iataCode].includes('VCS')) return 'QH_VCS'
    // NOTE: Điều kiện vé cho VN_VCS
    if (flight.airline === 'VN' && [flight.departure?.IATACode, flight.departure?.iataCode, flight.arrival?.IATACode, flight.arrival?.iataCode].includes('VCS')) return 'VN_VCS'
    if (flight.operating === 'BL') return flight.operating
    if (flight.airline.trim() === 'VN' && flight.flightNumber.startsWith('6') && flight.flightNumber.length === 4) return 'BL'
    if (flight.airline === 'VJ' && [flight.arrival.iataCode, flight.departure.iataCode].some(item => iataCodeVJ2.includes(item))) return 'VJ_2'
    if (flight.airline === 'VJ' && [flight.arrival.iataCode, flight.departure.iataCode].some(item => iataCodeVJ3.includes(item))) return 'VJ_3'
    return flight.airline.trim()
  }

  // Lấy iataCode khi flight nằm trong itineraries (case VJ đi Úc có transit bị sai hành lý xách tay)
  function getPointsFlightInItineraries(flight, reservationsData) {
    const itineraryHaveFlight = reservationsData.itineraries.find(itinerary => itinerary.find(trip => trip.segmentId === flight.segmentId))
    if (!itineraryHaveFlight) return [null, null]
    const len = itineraryHaveFlight.length
    const departureCode = itineraryHaveFlight[0]?.departure?.iataCode
    const arrivalCode = itineraryHaveFlight[len - 1]?.arrival?.iataCode
    return [departureCode, arrivalCode]
  }

  function matchedKgOrPCInDescription1G(text) {
    const regex = /\d+(KG|Kg|kg|Kilograms|kilograms|Kilogram|kilogram|PC|pc|Pc)/
    const match = text.match(regex)
    return match ? match[0] : ''
  }

  function getBaggageInBookingDetail(flight, reservationsData, locale = 'vi') {
    const baggageAllowance = flight?.baggageAllow || reservationsData.paxLists?.find(pax => !isEmpty(pax?.baggageAllowances))?.baggageAllowances || []
    const source = flight?.source || reservationsData?.source
    const result = {
      carryBag: null,
      checkinBag: null,
    }
    // Hành lý QH lấy từ bagAllowances trong api
    if (source === 'QH' && reservationsData.paxLists && reservationsData.paxLists.length
    && baggageAllowance
    // && baggageAllowance.length
    ) {
      // QH xách tay luôn = 7 với ECONOMY , 14 với BUSSINESS)
      result.carryBag = flight?.groupClass?.toUpperCase().includes('ECONOMY') ? '7kg' : flight?.groupClass?.toUpperCase().includes('BUSINESS') ? '14kg' : ''
      result.checkinBag = baggageAllowance.length
        ? baggageAllowance
          .filter(item => item.segmentId === flight?.itineraryId || item.segmentId === flight?.segmentId)
          .map(bag => {
            const { unit, weight } = bag.baggageInfo
            return unit && weight ? `${weight}${unit?.toUpperCase() === 'K' ? 'kg' : unit}` : ''
          }).join()
        : 'Không bao gồm'
      if (!result.checkinBag) result.checkinBag = 'Không bao gồm'
    } else if ( // Hành lý Quốc tế
      reservationsData.paxLists && reservationsData.paxLists.length
      && !reservationsData.isDomestic && !['VU', 'QH', '1A', 'TR', 'JQ'].includes(source)
    ) {
      if (source === 'AK') {
        if (['EC', 'EP', 'HF'].includes(flight?.groupClass)) {
          result.carryBag = '7kg'
        }
      } else if (['1S', '1S_CTRL'].includes(source)) {
        result.carryBag = getFareRulesAirlineByBookingClass(getAirlineByOperating(flight), flight.groupClass
          && flight.groupClass.length ? flight.groupClass : flight.bookingClass).boardingBaggage
      } else {
        const cabinBagArray = baggageAllowance
          .filter(item => String(item.segmentId) === String(flight.itineraryId)
            || String(item.segmentId) === String(flight.segmentId))
          .filter(i => {
            // VN1A Quốc tế lấy theo api => nhưng api chưa trả về => hiển thị liên hệ booker
            if (['VN1A', 'VN1A_MT'].includes(source)) return false
            if (source === 'VJ') {
              return i.baggageInfo.description[0].includes('Cabin Bag')
            }
            if (source === '1G') {
            // FIXME: CHECK BAGGAGE 1G
              const desc = i.baggageInfo.descriptions.join(',').toLowerCase()
              const bag = desc.includes('carry') || desc.includes('cabin')
              return bag && false // an hlxt 1g
            }
            if (('F1').includes(source)) return false
            if (('TH').includes(source)) {
              return (i.baggageInfo?.descriptions || []).join(' ').includes('carry')
            }
            return i
          })
          .map(bag => {
            const unit = bag?.baggageInfo?.unit?.toUpperCase() === 'K' ? 'KG' : bag?.baggageInfo?.unit || ''
            if (['VN1A', 'VN1A_MT', '1G'].includes(source)) {
              if (bag.number && bag?.baggageInfo?.weight && unit) {
                return `${bag.number} ${bag?.baggageInfo?.weight && unit ? `x(${bag?.baggageInfo?.weight}${unit})` : ''}`
              }
              if (bag.number) {
                return `${bag.number}`
              }

              return bag?.baggageInfo?.weight && unit ? `${bag?.baggageInfo?.weight}${unit}` : ''
            }
            if (source === 'VJ') {
              return `${`${bag?.baggageInfo?.weight}${unit}` || bag?.baggageInfo?.description}`
            }
            if (('TH').includes(source)) {
              return `${bag?.baggageInfo?.weight}${bag?.baggageInfo?.unit}`
            }
            return `${bag.number} ${bag.baggageInfo && bag.baggageInfo.weight && bag.baggageInfo.unit ? `(${bag.baggageInfo.weight}${bag.baggageInfo.unit} - ${bag.baggageInfo.description || bag.baggageInfo.descriptions})` : ''}`
          })
        const cabinBagSet = new Set(cabinBagArray)
        result.carryBag = Array.from(cabinBagSet).join(', ')
          || (['VJ'].includes(source)
            ? getFareRulesAirlineByBookingClass(
              [...getPointsFlightInItineraries(flight, reservationsData)].some(item => iataCodeVJ2.includes(item))
                ? 'VJ_2'
                : [...getPointsFlightInItineraries(flight, reservationsData)].some(item => iataCodeVJ3.includes(item))
                  ? 'VJ_3'
                  : 'VJ', flight.groupClass,
            ).boardingBaggage
            : '')
          || ''
      }
      const checkinBagArray = baggageAllowance
        .filter(item => item.segmentId === flight.segmentId || (item.segmentId === flight.itineraryId && !['VN1A', 'VN1A_MT'].includes(source)))
        .filter(i => {
          if (source === 'VJ') {
            return !i.baggageInfo.description[0].includes('Cabin Bag')
          }
          if (source === '1G') {
            const desc = i.baggageInfo.descriptions.join(',').toLowerCase()
            const bag = !(desc.includes('carry') || desc.includes('cabin'))
            return bag
          }
          if (('TH').includes(source)) {
            return !((i.baggageInfo?.descriptions || []).join(' ').includes('carry'))
          }
          return i
        })
        .map(bag => {
          if (source === 'QH' || source === 'AK') return `${bag.baggageInfo && bag.baggageInfo.weight && bag.baggageInfo.unit ? `${bag.baggageInfo.weight}${bag.baggageInfo.unit}` : ''}`
          if (source === '1G') {
            const unit = bag?.baggageInfo?.unit === 'K' ? 'KG' : bag.baggageInfo.unit
            let text = null
            if (bag.number && bag.baggageInfo && bag.baggageInfo.weight && unit) {
              text = `${bag.number} x(${bag?.baggageInfo?.weight}${bag?.baggageInfo?.unit})`
            } else if (bag.baggageInfo && bag.baggageInfo.weight && unit) {
              text = `${bag.baggageInfo.weight}${unit}`
            } else if (bag.number && bag.baggageInfo && bag.baggageInfo.descriptions[0]) {
              text = `${bag.number} (${bag.baggageInfo.descriptions[0]})`
            } else if (matchedKgOrPCInDescription1G(bag.baggageInfo?.descriptions.join(' '))) {
              text = matchedKgOrPCInDescription1G(bag.baggageInfo?.descriptions.join(' '))
            }
            return text
          }
          if (['VN1A', 'VN1A_MT'].includes(source)) {
            const unit = bag.baggageInfo?.unit
            if (bag.number && bag?.baggageInfo?.weight && unit) {
              return `${bag.number} ${bag?.baggageInfo?.weight && unit ? `x(${bag?.baggageInfo?.weight}${unit})` : ''}`
            }
            if (bag.number) {
              return `${bag.number}`
            }

            return bag?.baggageInfo?.weight && unit ? `${bag?.baggageInfo?.weight}${unit}` : ''
          }
          if (source.includes('1S')) {
            let baggageInfo = null
            const fareRule = getFareRulesAirlineByBookingClass(getAirlineByOperating(flight), flight?.groupClass?.length ? flight.groupClass : flight.bookingClass)
            if (fareRule && (fareRule?.cabinName?.toLowerCase().includes('phổ thông') || fareRule?.cabinName?.toLowerCase().includes('eco'))) {
              baggageInfo = '23KG'
            } else if (fareRule && (fareRule?.cabinName?.toLowerCase().includes('thương gia') || fareRule?.cabinName?.toLowerCase().includes('business'))) {
              baggageInfo = '32KG'
            }
            return `${bag.number} ${baggageInfo ? `x(${baggageInfo})` : ''}`
          }
          if (source === 'VJ') {
            return `${bag?.baggageInfo?.description || bag?.baggageInfo?.description || `${bag?.baggageInfo?.weight}${bag?.baggageInfo?.unit}`}`
          }
          if (('F1', 'TH').includes(source)) {
            return `${bag?.baggageInfo?.weight}${bag?.baggageInfo?.unit}`
          }
          return `${bag.number} ${bag.baggageInfo && bag.baggageInfo.weight && bag.baggageInfo.unit ? `(${bag.baggageInfo.weight}${bag.baggageInfo.unit} - ${bag.baggageInfo.description || bag.baggageInfo.descriptions})` : ''}`
        })

      const checkinBagSet = new Set(checkinBagArray)
      result.checkinBag = Array.from(checkinBagSet).join(', ') || 'Không bao gồm'
    } else if (['1A', 'TR', 'JQ'].includes(source)) {
      if (['TR', 'JQ'].includes(source)) {
        const bag = baggageAllowance.find(it => it?.segmentId === flight?.segmentId)
        result.carryBag = bag ? `${bag.number} ${bag?.baggageInfo?.weight && bag?.baggageInfo?.unit ? `x(${bag?.baggageInfo?.weight}${bag?.baggageInfo?.unit})` : ''}` : ''
      }
      const dataBaggByPax = reservationsData.paxLists?.[0]?.bagCheckin
      if (!isEmpty(dataBaggByPax)) {
        const getBagInSegment = dataBaggByPax.find(b => b?.segmentId === flight?.segmentId)
        result.checkinBag = getBagInSegment && getBagInSegment.bagWeight && getBagInSegment.bagWeightUnit ? `${getBagInSegment.bagWeight} ${getBagInSegment.bagWeightUnit}` : null
      }
    } else {
      let cabinClass = flight.groupClass && flight.groupClass.length ? flight.groupClass : flight.bookingClass
      if (source === 'VU') cabinClass = flight.fareType
      result.carryBag = getFareRulesAirlineByBookingClass(getAirlineByOperating(flight), cabinClass).boardingBaggage
      const checkinBag = getFareRulesAirlineByBookingClass(getAirlineByOperating(flight), cabinClass).checkinBaggage
      result.checkinBag = ['Có phí', 'NONE', 'FEE'].includes(checkinBag) ? 'Không bao gồm' : checkinBag
    }

    if (locale === 'en' && result?.carryBag && result?.checkinBag) {
      result.carryBag = result.carryBag.replace(/kiện/i, 'PC').replace(/Không bao gồm/i, 'Not included')
      result.checkinBag = result.checkinBag.replace(/kiện/i, 'PC').replace(/Không bao gồm/i, 'Not included')
    }
    return result
  }

  // lấy thông tin dịch vụ mua thêm từ bookingData
  // 1S, QH, VJ
  const resolveSsrData = (bookingData, filter = 'all') => {
    const result = []
    const {
      ancillaryServices, paxLists, itineraries, source,
    } = bookingData

    if (['VN1A_MT', 'VN1A', '1S', '1S_CTRL', 'QH', 'VJ', 'VU'].includes(source) && !isEmpty(ancillaryServices)) {
      const seatArr = ancillaryServices.filter(item => itineraries.flat().map(item => item.segmentId).includes(item.segmentIds[0]) && (item.serviceType === 'SEAT'))

      if (!isEmpty(seatArr) && (filter !== 'BAGGAGE')) {
        const mapSeat = seatArr.map(item => ({
          ...item,
          passenger: paxLists.find(p => p.paxId === item.paxId),
          segments: [itineraries.flat().find(s => s.segmentId === item.segmentIds[0])],
        }))

        mapSeat.forEach(item => {
          result.push(item)
        })
      }

      const baggageArr = ancillaryServices.filter(item => (item.serviceType === 'BAGGAGE') && itineraries.flat().map(s => s.segmentId).includes(item.segmentIds[0]))

      if (!isEmpty(baggageArr) && (filter !== 'SEAT')) {
        const mapBaggage = baggageArr.map(item => ({
          ...item,
          passenger: paxLists.find(p => p.paxId === item.paxId),
          segments: itineraries.flat().filter(s => (item.segmentIds.includes(s.segmentId))),
        }))

        mapBaggage.forEach(item => {
          result.push(item)
        })
      }

      return result.sort((a, b) => a.paxId.localeCompare(b.paxId))
    }

    return result
  }

  // != 1S
  function resolveBaggageBySegment(segmentData) {
    const bgData = {
      text: '-',
      data: null,
    }

    if (isEmpty(segmentData.ancillaryServices)) return bgData

    const getXbag = segmentData.ancillaryServices
      .filter(item => (item.serviceType === 'BAGGAGE')
      && (item.paxId === segmentData.passenger.paxId)
        && (
          ['VJ', 'VZ'].includes(segmentData.segment.airline)
            ? (item.segmentIds.includes(segmentData.segment.itineraryId) || item.segmentIds.includes(segmentData.segment.segmentId))
            : item.segmentIds.includes(segmentData.segment.segmentId)
        ))

    if (!isEmpty(getXbag)) {
      if (['QH'].includes(segmentData.segment.airline)) {
        bgData.text = resolveSsrNameByCode(getXbag[0].serviceSubCode)
        bgData.data = getXbag.map(xbagItem => ({
          ...xbagItem,
          ssrName: resolveSsrNameByCode(xbagItem.serviceSubCode),
        }))
      } else if (['VU', 'VJ', 'VZ'].includes(segmentData.segment.airline)) {
        bgData.text = 'ok'
        bgData.data = getXbag.map(xbag => ({
          ssrName: ['VU'].includes(segmentData.segment.airline) ? resolveSsrNameByCode(xbag.serviceSubCode) : resolveSsrNameByCode(xbag.serviceInfo.description),
          quantity: xbag.numberOfItems,
          dataItem: xbag,
        }))
      } else {
        bgData.text = ''
        bgData.data = getXbag.map(xbag => ({
          ssrName: resolveSsrNameByCode(xbag.serviceCode),
          quantity: xbag.numberOfItems,
          dataItem: xbag,
        }))
      }
    }
    return bgData
  }

  function getCabinClass(segment) {
    if (segment.airline === 'QH') {
      // FIXME - fix cho những case QH không có groupClass, cần update thêm bookingClass
      if (!segment.groupClass && segment.bookingClass) return GROUP_CLASS_BY_CLASS_BOOKING[segment.bookingClass] || ''

      if (segment.groupClass.includes('ECONOMY')) return 'ECONOMY'
      if (segment.groupClass.includes('BUSINESS')) return 'BUSINESS'
    }

    return segment.groupClass
  }

  // Support
  function resolveSegmentData(trip, { paxLists, ancillaryServices }) {
    const data = []

    trip.forEach(segment => {
      const passengers = paxLists.filter(p => p.paxType !== 'INFANT')

      passengers.forEach((p, pIndex) => {
        let segmentIndex
        switch (segment.airline) {
          case 'QH':
            segmentIndex = segment.devAddonsSegmentIndex
            break
          default:
            segmentIndex = segment.segmentId
            break
        }

        data.push({
          passenger: {
            ...p,
            passengerIndex: pIndex + 1,
          },
          segment: {
            ...segment,
            flights: `${segment.departure.iataCode}-${segment.arrival.iataCode}`,
            segmentIndex: String(segmentIndex),
            // FIXME: QH tạm ẩn V3 ko trả về groupClass
            cabinClass: getCabinClass(segment),

            // cabinClass: (segment.airline === 'QH' && segment.groupClass.includes('ECONOMY'))
            //   ? 'ECONOMY' : (segment.airline === 'QH' && segment.groupClass.includes('BUSINESS'))
            //     ? 'BUSINESS' : segment.groupClass,
            // cabinClass: segment.groupClass,
          },
          ancillaryServices,
        })
      })
    })

    return data
  }

  function setLowFareTracking({ id, payload }) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/setLowFareTracking', { id, payload })
        .then(res => {
          resolve(res)
          refetchBookingData()

          toastSuccess({
            title: 'messagesList.success',
            content: 'Đã bật tính năng canh vé giá rẻ!',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi khi thực hiện hành động, vui lòng kiểm tra lại thông tin!',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function cancelLowFareTracking({ id, payload }) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/cancelLowFareTracking', { id, payload })
        .then(res => {
          resolve(res)
          refetchBookingData()

          toastSuccess({
            title: 'messagesList.success',
            content: 'Đã tắt tính năng canh vé rẻ!',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi khi thực hiện, vui lòng kiểm tra lại thông tin!',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function customServiceFee(id, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/customServiceFee', { id, payload })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: 'Đã cập nhật sửa phí dịch vụ thành công!',
          })
        })
        .then(() => {
          refetchBookingData()
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi khi thực hiện, vui lòng kiểm tra lại thông tin!',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function stopPaymentReminder(bookingData) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/stopPaymentReminder', { id: bookingData.id })
        .then(res => {
          resolve(res)
          toastSuccess({
            title: 'messagesList.success',
            content: `${bookingData.isPaymentReminder ? 'reservation.paymentReminder.deactivate' : 'reservation.paymentReminder.activate'}`,
          })
        })
        .then(() => {
          refetchBookingData()
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.errAction',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function enableEditOutside(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/enableEditOutside', id)
        .then(res => {
          resolve(res)
          refetchBookingData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'Mở khoá booking được đồng bộ vào hệ thống thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi mở khoá booking.',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function hideFareBooking(id) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/hideFareBooking', id)
        .then(res => {
          resolve(res)
          refetchBookingData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'Ẩn/hiện giá booking thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi ẩn/hiện giá booking.',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function assignBooking(id, payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/assignBooking', { id, payload })
        .then(res => {
          resolve(res)
          refetchBookingData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'Gán booking thành công',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi gán booking.',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function addMultiFftv(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/addMultiFftv', payload)
        .then(res => {
          resolve(res)
          refetchBookingData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'Thêm thẻ BSV thành công. Vui lòng kiểm tra lại thông tin hành khách.',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi thêm thẻ BSV',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function commandAddSsrs(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/commandAddSsrs', payload)
        .then(res => {
          resolve(res)
          refetchBookingData()
          toastSuccess({
            title: 'messagesList.success',
            content: 'Thêm dịch vụ đặc biệt thành công. Vui lòng kiểm tra lại thông tin hành khách.',
          })
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi thêm dịch vụ đặc biệt.',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function getAuditLogsFromSource(payload) {
    return new Promise((resolve, reject) => {
      loading.value = true
      store
        .dispatch('app-reservation/getAuditLogsFromSource', payload)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          toastError({
            title: 'messagesList.error',
            content: 'Lỗi tải lịch sử thao tác từ hãng.',
          })
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  function resolveFirstNamePaxRemoveTitle(pax) {
    const title = new Set(['MR', 'MRS', 'MSTR', 'MISS', 'MS'])
    if (!pax.title) {
      const names = pax.firstName.split(' ')
      const lastWord = names[names.length - 1]
      if (title.has(lastWord.toUpperCase())) {
        return names.slice(0, -1).join(' ')
      }
    }
    return pax.firstName
  }

  function resolveInsuranceItinerariesName(data, type) { // tripMulti, journeys
    const getArrSegmentName = ['journeys'].includes(type)
      ? data.map(j => `${j.from}-${j.to}`)
      : ['tripMulti'].includes(type) ? data.map(trip => `${trip.departure}-${trip.arrival}`)
        : data.map(trip => `${trip[0].departure.iataCode}-${trip[trip.length - 1].arrival.iataCode}`)
    // const listTransit = getArrSegmentName // .filter((item, index) => item !== getArrSegmentName[index + 1])
    return getArrSegmentName.join('-')
  }

  const insuranceIdPaxGenerate = pax => `${pax.paxType} ${pax.lastName} ${resolveFirstNamePaxRemoveTitle(pax)}`

  function getFlightByMultiPNRInsurances(bookingData) {
    const getTimeStamp = date => {
      const [datePart, offsetPart] = date.split('+')
      const thisDate = moment.utc(datePart).utcOffset(`+${offsetPart}`).valueOf()
      return thisDate
    }

    const wrapItinerary = bkData => ({
      code: bkData.bookingCode,
      paxLists: bkData.paxLists,
      itineraries: bkData.itineraries.map(trip => ({
        source: bkData.source,
        departure: trip[0].departure.iataCode,
        arrival: trip[trip.length - 1].arrival.iataCode,
        departureDate: convertISODateTime(trip[0].departure.at, trip[0].departure.timeZone).ISOdatetime,
        arrivalDate: convertISODateTime(trip[trip.length - 1].arrival.at, trip[trip.length - 1].arrival.timeZone).ISOdatetime,
      })),
    })

    const wrapThisItinerary = [wrapItinerary(bookingData)]
    const multiCodes = bookingData.relationBookings

    if (!isEmpty(multiCodes)) {
      const multiItinerary = [...multiCodes.map(bk => wrapItinerary(bk)), ...wrapThisItinerary]
      const wrapMultiItinerary = multiItinerary.sort((a, b) => getTimeStamp(a.itineraries[0].departureDate) - getTimeStamp(b.itineraries[0].departureDate))
      const result = wrapMultiItinerary.filter((it, index) => JSON.stringify(it.itineraries) !== JSON.stringify(wrapMultiItinerary[index + 1]?.itineraries))

      // console.log({ isMultipleCodes: true, allCodes: wrapMultiItinerary, data: result })

      return { isMultipleCodes: true, allCodes: wrapMultiItinerary, data: result }
    }

    return { isMultipleCodes: false, allCodes: wrapThisItinerary, data: wrapThisItinerary }
  }

  function isPaidSegment(itineraries, segment) {
    const allSegment = itineraries.flat(2)
    const segmentFinded = allSegment.find(seg => (seg.departure.iataCode === segment.departure) && (seg.arrival.iataCode === segment.arrival) && (seg.departure.at === segment.departureDate))
    return segmentFinded ? segmentFinded?.isPaid : false
  }

  // ANCHOR Hide feature
  const hideFeature = computed(() => store.getters['app-reservation/getHideFeature'])
  const toggleHideFeature = () => store.dispatch('app-reservation/toggleHideFeature')

  // ANCHOR copy pnr
  const { copy } = useClipboard()

  const disabledCopy = ref(false)
  function copyPnr(pnr) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copy(pnr)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  return {
    flightPayment,
    optionsAirLine,
    optionsSource,
    optionsStatus,
    perfectScrollbarSettings,
    cancelFlight,
    cancelFlight1G,
    cancelFlightPrice,

    fetchBookingById,
    fetchPastDayBookingById,
    fetchBookings,
    retrieveBooking,
    updateNote,
    updateAutoIssueTime,
    updateResetIssueTime,
    activeReservation,
    deActiveReservation,
    reBookReservation,

    tableColumns,
    sizePerPage,
    currentPage,
    totalReservations,
    dataMeta,

    sortBy,
    isActiveFilter,
    isSortDirDesc,

    refReservationListTable,

    resolveContactTypeVariant,

    resetStore,
    resetSearchStore,
    refetchData,
    clearFilter,

    searchTextFilter,
    bookingCodeFilter,
    createdByFilter,
    agencyCodeFilter,
    reservationsData,

    loading,
    loadingRefetchBooking,

    isEmptyFilter,
    startDateFilter,
    endDateFilter,
    startDateFlightFilter,
    endDateFlightFilter,
    statusFilter,
    statusFilterByUser,
    brandFilter,
    sourceFilter,

    fetchListAncillary,
    fetchSeatMap,
    getIsEmptySeatMapData,

    paxInfoPrice,
    modifyPaxInfo,
    modifyAncillary,
    ancillaryPayments,
    modifyReserveSeat,
    modifyCancelSeat,
    separateBooking,
    addFlights,
    changeFlights,
    addFlightsPrice,
    changeFlightsPrice,
    sendEmail,

    // Getters
    getBookingData,
    getAncillaryData,
    getSeatMapData,
    getOldItineraries,

    calculatePriceBooking,
    cleanAirPricingConversion,
    // recalculatePriceCommand,
    recalculatePriceCommandAddDob,
    recalculatePriceItinerary,

    exportBooking,
    filterAdultPassengers,
    modifyDocuments,
    modifyFrequentFlyer,
    fetchEticketByNumber,
    voidTicket,
    eTicketUpdates,
    getCommissions,
    checkTicketing,
    ticketing,
    // retrieveTicket,

    refundTicketAmount,
    refundTickets,
    exchangeTicketAmount,
    exchangeTickets,

    shortenBooking,
    confirmSegments,
    modifyRemarks,
    osiFlight,
    sendQueue,

    getAirportsByCodeList,
    getAirportByAirportCode,
    refetchBookingData,
    fetchAirportGroup,
    STATUS_NAME_ANCILLARY,
    getFareRulesAirlineByBookingClass,
    getBaggageWeightBySsrPaid,

    bookSpecialServices,
    getListSpecialServices, // lounge
    createSpecialServices,
    getInsurancePlans,
    bulkCreateInsuranceDraft,
    updateInsuranceDraft,
    deleteInsuranceDraft,
    createInsuranceCoverNote,
    editInsuranceCoverNote,
    createInsurancePolicy,
    cancelInsurance,
    resolveInsuranceItinerariesName,

    addArunksToItineraries,

    fetchAgencies,
    fetchCustomers,

    getAirlineByOperating,
    getBaggageInBookingDetail,

    resolveSsrData,
    resolveBaggageBySegment,
    resolveSegmentData,

    confirmFlightsSegments,
    setLowFareTracking,
    cancelLowFareTracking,

    currentBreakpoint,

    customServiceFee,
    stopPaymentReminder,
    retailReportCustomer,

    shortenEticket,
    enableEditOutside,
    assignBooking,
    loadingAgencies,
    loadingCustomers,
    hideFareBooking,

    getAuditLogsFromSource,
    banBooking,
    fetchPastDayBookings,
    unbanBooking,

    delay,
    resolveFirstNamePaxRemoveTitle,
    addMultiFftv,

    getFlightByMultiPNRInsurances,
    insuranceIdPaxGenerate,
    submitBooking,
    sendSubmitBookingMail,
    commandsCancelSegment,
    commandsGetBooking,
    commandAddSsrs,

    isPaidSegment,
    hideFeature,
    toggleHideFeature,
    addDocumentsVN1A,
    NDC_1A_BOOKING,

    copyPnr,
    disabledCopy,
  }
}

export function resolveAirPnr(supplierPNRs, airlineSegment) {
  if (isEmpty(supplierPNRs)) return ''
  let supplierPNR
  if (supplierPNRs.length === 1) {
    supplierPNR = supplierPNRs[0]
  } else {
    supplierPNR = supplierPNRs
      .find(i => (i.supplierCode === airlineSegment) || (i.supplierCode === '1A'))
  }

  if (supplierPNR) return `${supplierPNR?.supplierPNR}*${supplierPNR?.supplierCode}` || ''
  return ''
}
